.story-main-wrap {
  padding: 70px 0;
}

/* .our-company-image-area {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
    gap: 1vw;
} */
.our-company-single-image.col {
  overflow: hidden;
}
.our-company-single-image:hover > img {
  transform: scale(1.05);
  border-radius: 10px;
}

.our-company-single-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 0.3s;
}

.Process-title-left span {
  width: 15px;
  height: 15px;
  display: block;
  background: red;
  border-radius: 3px;
  margin-bottom: 15px;
}
.Process-title-left > span {
  display: none;
}
/* .story-inner-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5vw;
  align-items: center;
} */

.story-right-content {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 3vw;
  padding: 25px 0;
  /* background: aliceblue; */
  justify-content: center;
}
.story-right-main-wrap {
  background: transparent;
  padding: 40px 0;
}
.story-right-main-wrap.about-us-page {
  background: #f0f8ff;

  padding: 0px 0;
}
.story-right-single-item {
  width: 18%;
  text-align: center;
}

.story-right-single-item h1 {
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.story-right-single-item h1:after {
  width: 80%;
  height: 6px;
  content: "";
  background: #1b3f73;
  position: absolute;
  left: 0;
  bottom: -10px;
}

.story-right-single-item h1::before {
  width: 20%;
  height: 6px;
  content: "";
  background: red;
  position: absolute;
  right: 0;
  bottom: -10px;
}

.story-left-content .Process-title-area {
  padding: 0;
}

@media only screen and (max-width: 992px) {
  .about-page-title.text-center.my-4 {
    padding: 10px 5%;
  }
  .our-process-wrap.our-process-2 {
    flex-flow: wrap-reverse;
    display: flex;
  }
  .story-inner-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
  .story-right-content {
    padding: 10% 0;
    gap: 1vw;
    flex-wrap: wrap;
  }
  .story-right-single-item {
    width: 47%;
    text-align: center;
  }
}

@media only screen and (max-width: 770px) {
  .our-company-image-area {
    grid-template-columns: repeat(auto-fill, minmax(46vw, 1fr));
  }

  .story-inner-wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5vw;
    align-items: center;
  }

  .story-right-content {
    padding: 10% 0;
    gap: 1vw;
    flex-wrap: wrap;
  }

  .story-right-single-item h1 {
    font-size: 18px;
    font-weight: 600 !important;
  }

  .story-right-single-item {
    width: 47%;
    text-align: center;
  }

  .our-process-wrap.our-process-2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .scroll-area.moveToTop {
    margin-top: 71vh;
  }
}

.our-company-image-area.row > div {
  margin-bottom: 30px;
}
.story-left-content h3 {
  font-size: 30px;
  font-weight: 800 !important;
  font-family: "Raleway";
  color: #232536;
  line-height: 44px;
}

.our-process-full-width-area {
  background: #f9fbff;
}
.Process-title-left h2 > span {
  display: inline-block;
  width: unset;
  background: none;
  color: #1b3f73;
  margin-left: 7px;
  height: unset;
  margin-bottom: 0px !important;
}
.Process-title-left h2 {
  display: flex;
  width: 100%;
  justify-content: center;
}

.Process-title-left h2 span span {
  width: unset;
  display: inline-block;
  background: none;
  color: red;
  height: unset;
  margin-bottom: 0px !important;
}
span.Process-title-span {
  font-family: Born Ready Slanted;
  left: 0;
  white-space: nowrap;
  font-weight: 900;
  position: relative;
  letter-spacing: 3px;
}
@media only screen and (max-width: 576px) {
  .story-left-content h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .Process-title-left p {
    line-height: 21px;
    font-size: 13px;
  }
  .scroll-area.moveToTop {
    margin-top: 71vh;
  }
}
@media only screen and (max-width: 400px) {
  .scroll-area.moveToTop {
    margin-top: 70vh;
  }
}

.about-page-title.text-center.my-4 h2 {
  position: relative;
  margin-bottom: 30px;
  display: inline-block;
}

.about-page-title.text-center.my-4 h2:after {
  content: "";
  width: 30%;
  height: 6px;
  position: absolute;
  left: 35%;
  bottom: -16px;
  background: #1b3f73;
}
.about-page-title.text-center.my-4 {
  padding: 20px 0;
}
/* ========================================= */
.our-process-wrap {
  display: flex;
  align-items: center;
  gap: 4vw;
  padding: 50px 0;
}

.our-process-left {
  width: 60%;
}

.our-process-left h6 {
  letter-spacing: 3px;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.our-process-left h2 {
  margin-bottom: 26px;
  font-size: 50px;
}

.our-process-left ul {
  padding-left: 00px;
}

.our-process-left ul li {
  line-height: 26px;
  font-size: 14px;
  color: #232536;
}
.our-process-left ul li {
  line-height: 26px;
  font-size: 14px;
  color: #232536;
  list-style: none;
}
.our-process-right {
  width: 35%;
}

.our-process-right img {
  width: 100%;
}

.call-to-action-area {
  background: #1b3f73;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6% 5%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.call-to-action-left h2 {
  color: #fff;
  font-weight: 600;
  font-size: 34px;
}

.call-to-action-main-area {
  padding: 50px 0;
}

.call-hero-button-area a {
  margin-right: 15px;
}
.call-hero-button-area a:last-child {
  margin-right: 0px;
}
@media only screen and (max-width: 992px) {
  .call-hero-button-area a {
    margin-right: 10px;
  }
  .our-process-wrap {
    display: block;
  }
  .our-process-left {
    width: 100%;
    margin-bottom: 50px;
  }
  .our-process-right {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .call-hero-button-area a {
    margin-right: 10px;
  }
  .our-process-wrap {
    display: block;
  }
  .our-process-left {
    width: 100%;
    margin-bottom: 50px;
  }
  .our-process-right {
    width: 100%;
  }
  .hero-inner-left {
    width: 100%;
  }
  .hero-inner-right {
    width: 100%;
  }
  .call-to-action-area {
    display: block;
    text-align: center;
  }
  .call-to-action-input-wrap {
    /* width: 300px; */
    margin: 20px auto;
  }
}

/* ========================================= */
.hero-inner-area.shomriddhi-hero {
  background: #ffffff;
  height: 70vh;
  display: flex;
  width: 100%;
  box-shadow: 0px 0px 20px 0px #dddddd52;
  margin-top: 30px;
  border-radius: 10px;
  padding: 5%;
  position: relative;
}
.hero-inner-left > p {
  padding-top: 25px;
}
.call-to-action-area.shomriddhi-hero {
  background: #ec7323;
}

.hero-main-area {
  background: #fff;
}

.hero-inner-area.shomriddhi-hero h2 {
  color: #1b3f73;
}

.hero-inner-area.ilsx-hero {
  background: #0052cc;
}

.call-to-action-area.ilsx-hero {
  background: #0052cc;
}

.hero-inner-area.salesx-hero {
  background: #de1f26;
}

.call-to-action-area.salesx-hero {
  background: #de1f26;
}

.hero-inner-area.salesx-hero h2 span {
  color: #1b3f73;
}

.call-to-action-input-wrap {
  display: block;
  align-items: center;
  /* background: #fff; */
  outline: none;
  height: 40px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.call-to-action-right input {
  border: none;
  padding: 0px 10px;
  color: #1b3f73;
  height: 50px;
  width: 380px;
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.call-to-action-right input:focus {
  outline: none;
}

.call-to-action-right input::placeholder {
  color: #1b3f73;
  font-size: 16px;
}

.call-to-action-input-wrap button {
  border: none;
  padding: 0 30px;
  color: #1b3f73;
  background: #fff;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  font-weight: 700;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.retailx-hero {
  background: #7020f2;
}

.hero-inner-left > img {
  height: 70px;
}

@media only screen and (max-width: 992px) {
  .call-to-action-right input {
    width: 210px;
  }
  .hero-inner-area.shomriddhi-hero {
    height: 100%;
  }
  .hero-inner-left {
    text-align: center;
  }
  .hero-inner-area.shomriddhi-hero > .row {
    flex-direction: column-reverse;
  }
  .hero-inner-right img {
    margin-bottom: 20px;
  }
  .hero-inner-area.shomriddhi-hero .hero-inner-left {
    padding-right: 0%;
  }
  .hero-inner-area.shomriddhi-hero > * > * > .hero-inner-left {
    width: 70%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 770px) {
  .call-to-action-input-wrap button {
    margin-left: 5px;
  }

  .call-to-action-area {
    padding: 7% 0;
  }

  .call-to-action-right input {
    width: 237px;
    display: block;
    margin: 0 auto;
  }

  .call-to-action-input-wrap button {
    margin-left: 0;
    margin-top: 30px;
    height: unset;
    padding: 10px 30px;
  }

  .call-to-action-area {
    padding: 30% 0;
    padding-top: 10%;
  }
  .hero-inner-area.shomriddhi-hero {
    height: 100%;
  }
  .hero-inner-area.shomriddhi-hero .hero-inner-left {
    padding-right: 0%;
  }
}

.hero-inner-area.shomriddhi-hero:after {
  width: 50%;
  content: "";
  position: absolute;
  height: 10px;
  background: red;
  bottom: 0;
  left: 0;
}
.hero-inner-area.shomriddhi-hero:before {
  width: 50%;
  content: "";
  position: absolute;
  height: 10px;
  background: #1b3f73;
  bottom: 0;
  right: 0;
}

.our-process-right.big-image-somriddhi {
  width: 65%;
}

.our-process-left.big-image-somriddhi {
  width: 35%;
}
.our-process-left.big-image-somriddhi h2 {
  font-size: 44px;
}

.Process-title-left.text-visible-para p {
  display: block;
}
.process-items > * > * > * {
  height: 100%;
}

.our-process-left.big-image-somriddhi ul {
  padding-left: 0;
}

.hero-inner-area.shomriddhi-hero > * > * > .hero-inner-left {
  width: 70%;
}

.our-process-right {
  width: 50%;
}

.process-items > * > * {
  margin-bottom: 20px;
}

.our-process-right-.text-end img {
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .call-to-action-input-wrap button {
    margin-left: 5px;
  }

  .call-to-action-area {
    padding: 7% 0;
  }

  .call-to-action-right input {
    width: 237px;
  }

  .call-to-action-input-wrap button {
    margin-left: 0;
    margin-top: 30px;
    height: unset;
    padding: 10px 30px;
  }

  .call-to-action-area {
    padding: 30% 0;
    padding-top: 10%;
  }
  /* .feature-main-area {
    padding-bottom: 0;
  } */
  .hero-inner-area.shomriddhi-hero > * > * > .hero-inner-left {
    width: 100%;
  }
  .our-process-left.big-image-somriddhi {
    width: 100%;
  }
  .our-process-right.big-image-somriddhi {
    width: 100%;
    text-align: center;
  }
  .hero-inner-area.shomriddhi-hero {
    height: 98vh;
  }
  .our-process-left.big-image-somriddhi h2 {
    font-size: 30px;
  }
  .our-process-left h2 {
    margin-bottom: 15px;
    font-size: 30px;
  }
  .our-process-right {
    width: 100%;
  }
}
