.header-logo a {
  display: inline-block;
  height: 40px!important;
}

.header-logo a img {
  height: 100%;
}
.bg-black {
  --bs-bg-opacity: unset;
  background-color: unset;
}

/* header.header-main-area > * {
  height: 80px;
}
header.header-main-area > * > * {
    height: 80px;
}
header.header-main-area > * > * > * {
    height: 80px;
}
header.header-main-area > * > * > * > * {
    height: 80px;
}
header.header-main-area > * > * > * > * > * {
    height: 80px;
}
header.header-main-area > * > * > * > * > * > * {
    height: 80px;
} */
