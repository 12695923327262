@import "./variable.scss";

.create-btn {
  padding-left: 20px !important;
  padding-right: 20px !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  background-color: $centro_primary_color !important;
  border: 1px solid $centro_primary_color !important;
}

.dark-btn {
  padding-left: 20px !important;
  padding-right: 20px !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  background-color: $centro_dark_color !important;
  border: 1px solid $centro_dark_color !important;
}

.product_card {
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .category {
    color: $centro_primary_color;
    font-size: 8px;
    background-color: #ca2d071f;
    padding: 3px 8px;
    display: inline-block;
    border-radius: 30px;
    font-weight: 600;
  }

  .card_content {
    padding: 12px;

    h3 {
      font-size: 16px;
      color: $centro_dark_color;
      margin-bottom: 4px;
    }

    p {
      font-size: 12px;
      color: #667085;
    }
  }
}

.add_product_section {
  .ql-editor.ql-blank {
    height: 100px;
  }
}

.product_card {
  .action {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .action {
      opacity: 1;
      visibility: visible;
    }
  }
}
