* {
  margin: 0;
  /* transition: 0.3s; */
  /* transition: 0.3s; */
}

body {
  /* font-family: "Hind Siliguri", sans-serif; */
  scroll-behavior: smooth;
  background: #fff !important;
}
i.fab {
  font-family: "Font Awesome 6 Brands" !important;
  font-weight: 400;
}
p {
  color: #232536;
  margin-bottom: 0;
}

h6 {
  margin-bottom: 0;
}

.text-center {
  text-align: center;
}

.App {
  overflow: hidden;
}

.slick-track {
  display: flex;
  align-items: center;
}

body::-webkit-scrollbar {
  width: 10px;
  /* display: none; */
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: red;

  border-radius: 10px;
  background: #1b3f73;
}
.about-research-inner-area img {
  width: 100%;
}
.service-items img {
  width: unset;
}
.all-page-logo {
  position: fixed;
  right: 0;
  z-index: -9;
  opacity: 0.9;
  top: 20%;
}

.all-page-logo img {
  width: 300px;
  position: relative;
  right: -80px;
}

/* 
.single-post-content {
  padding: 20px 25px;
  background: #ffffff;
  position: relative;
} */

header.header-main-area.bg-black {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  background: #fff !important;
  height: 80px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 6px 5px rgb(174 174 174 / 20%);
}

header.header-main-area.bg-white {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  background: #fff !important;
  height: 80px;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

a.navbar__link.navbar__link--active:after {
  width: 100%;
  height: 3px;
  background: red;
  position: absolute;
  content: "";
  bottom: -1px;
  left: 0;
  display: none;
}

.social-media-link-wrap {
  position: fixed;
  width: 50px;
  line-height: 54px;
  height: 162px;
  background: #fff;
  z-index: 99;
  top: 36%;
  border-radius: 0px 8px 8px 0;
}

.social-media-link-wrap a {
  display: block;
  font-size: 30px;
  /* color: gray; */
  text-align: center;
}

.social-media-link-wrap i.fab.fa-twitter {
  color: #55adee;
}

.social-media-link-wrap i.fab.fa-facebook {
  color: #4867aa;
}

.social-media-link-wrap i.fab.fa-linkedin {
  color: #0a66c2;
}
.desktop-hide-nav-item {
  display: none !important;
}
@media only screen and (max-width: 992px) {
  .social-media-link-wrap {
    top: unset;
    bottom: 0;
    display: none;
  }
  .desktop-hide-nav-item {
    display: block !important;
  }
}

.scroll-area {
  position: fixed;
  background: #1b3f73;
  width: 50px;
  height: 50px;

  border-radius: 10px;

  padding-top: 8px;
  right: 2%;

  transition: 2s;
  margin-top: -500px;
  z-index: 9999999999999;
}

.scroll-area.moveToTop {
  margin-top: 71vh;
}
.scroll-area a {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
}

img.slider-logo {
  display: none;
}

.your-need-single-item-data > p {
  font-weight: 600;
}

.footer-top-item-left > * > p {
  font-size: 11px;
}
.footer-left-text-p p {
  padding-left: 80px;
  font-size: 13px;
  font-weight: 500;
}

ul.footer-2nd-link li a {
  font-size: 9px !important;
}
.footer-top-right-single-item.pl-2 {
  margin-top: 14px;
}

.insights-hero-area {
  width: 100%;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 79px;
}
.insights-hero-area:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background: #00000030;
  top: 0;
}
.insights-header-main-wrap {
  height: 80px;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 10px 0px #dddddd85;
  position: sticky;
  top: 80px;
  z-index: 999;
}

ul.insght-middle-header-wrap {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

ul.insght-middle-header-wrap {
  width: 100%;
}
ul.insght-middle-header-wrap li {
  list-style: none;
}
.insights-header-inner-wrap {
  display: flex;
  align-items: center;
  gap: 10%;
  justify-content: space-between;
}
.insights-header-left {
  /* width: 15%; */
  /* float: left; */
}
.insights-header-left > a {
  padding: 0;
  display: block;
  padding-top: 10px;
}
.insights-header-left > a > img {
  height: 100%;
}
.insights-header-right {
  /* float: left; */
  width: 79%;
}
.insight-post-title {
  margin: 50px 0;
}

.insights-data-title-wrap {
  display: flex;
  justify-content: space-between;
}
.insight-post-items-wrap {
  margin-bottom: 50px;
}
.insight-post-items-wrap > * > * > * {
  margin-bottom: 25px;
}
.single-post-content > h4 > a {
  color: unset;
  text-decoration: none;
}
.insght-destail-article-time {
  display: flex;
  gap: 36px;
}

.insght-destail-article-time > span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.insights-data-title-wrap.detailse-page-header {
  margin-bottom: 30px;
}
.insght-destail-article-time.inner-detailspage > span {
  display: flex;
  align-items: center;
  gap: 20px;
}
.insght-destail-article-time.inner-detailspage {
  gap: 2vw;
}
.insights-data-title-wrap.detailse-page-header.details-people {
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 60px;
  margin-bottom: 30px;
}

.article-middle-tab-data.post-article-5 .i.insights-tab-main-wrap-1st {
  border-bottom: 1px solid #89c4de;
}
.insight-tab-content-left {
  /* border-bottom: 1px solid #cbcbcb; */
  margin-bottom: 40px;
}
.insight-tab-content-left.insight-5 {
  border-bottom: none;
}
.post-content-area > * {
  text-align: center;
  /* display: inline-block; */
}

.post-content-area > * > p {
  text-align: left;
}

.post-content-area > * > h4 {
  text-align: left;
}
.post-content-area > * > ul > li {
  list-style-type: decimal;
  text-align: left;
  margin-bottom: 20px;
}
.insights-header-right .slick-next:before {
  /* content: "→"; */
  content: "\ea50";
  color: #1b3f73;
  font-family: boxicons !important;
}
.insights-header-right .slick-prev:before {
  /* content: "→"; */
  content: "\ea4d";
  color: #1b3f73;
  font-family: boxicons !important;
}
.insights-header-right button.slick-arrow.slick-next {
  width: 30px;
  height: 30px;
  border: 1px solid #1b3f73;
  border-radius: 50%;
}
.insights-header-right button.slick-arrow.slick-prev {
  width: 30px;
  height: 30px;
  border: 1px solid #1b3f73;
  border-radius: 50%;
}
.insights-data-title-wrap.detailse-page-header > * > p {
  background: #1b3f73;
}

.insights-hero-area.prasentation-article:after {
  display: none;
}
.insight-tab-content-left img {
  width: 100%;
}
/* ==== */
.article-page-details.data-page-5-post ul li {
  position: relative;
}
.article-page-details.data-page-5-post ul li:before {
  content: "\ea41";
  font-family: boxicons !important;
  position: absolute;
  left: -40px;
  font-size: 30px;
  color: #1b3f73;
}
.insight-tab-content-left h4 {
  margin-bottom: 30px !important;
}
.insght-destail-article-time.inner-detailspage > * > span > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.footer-bottom-left > p > span {
  font-size: 12px;
}
.insights-nav-items {
  text-align: center;
}
.post-content-area img {
  width: 100%;
}
.post-3-details-data {
  margin-bottom: 50px;
}
.insght-destail-article-time.inner-detailspage > * > span > p {
  font-size: 14px;
  margin-top: 2px;
}

.post-3-details-single-data > p {
  margin-bottom: 15px;
}

.post-3-details-single-data > h6 {
  margin-bottom: 5px;
}
.article-content-wrap.tab-post-hbakubdkgyukfbuvb {
  text-align: center;
  position: relative;
}
body > iframe {
  display: none !important;
}
.maac-website-loader {
  color: red !important;
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background: #fff;
  align-items: center;
  justify-content: center;
}
/* ========================================= */
footer.footer-main-area.e-square-footer .footer-bottom {
  padding: 15px 0 !important;
  background: #1b3f73;
}
.testimonial-main-area {
  /* background: #F9FBFF; */
  padding: 50px 0;
  padding-bottom: 0;
  padding-top: 110px;
}
.story-right-single-item h1 {
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  font-size: 36px;
  margin-top: 15px;
  font-family: sora;
}
.story-right-single-item svg {
  height: 50px;
}
div#layout-wrapper {
  background: #f1f1f1;
}
.account-pages.my-5.pt-sm-5 {
  background: #f1f1f1;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 100vh;
}
