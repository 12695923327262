body {
  font-family: "Sora", sans-serif;
}

.header-main-area-wrap {
  position: absolute;
  z-index: 9999;
  width: 100%;
  background: #fff;
  top: 0;
  &::after {
    content: "";
    background: rgba(255, 255, 255, 0.1803921569);
    position: absolute;
    width: 100%;
    height: 12px;
    bottom: -12px;
  }
}
a.header-right-singup-btn {
  background: #007cf3;
  padding: 6px 30px;
  text-decoration: none;
  border-radius: 5px;
  color: #fff;
}
.about-main-area-wrap {
  padding: 70px 0;
  p.about-top-text {
    color: #9c9ca5;
    margin-bottom: 5px;
  }
  p.about-bottom-text {
    margin-bottom: 0;
  }
}
.profile-main-area-wrap {
  padding: 70px 0;
  .profile-title {
    text-align: center;
    margin-bottom: 40px;
  }
  .profile-image-area-wrap {
    img {
      width: 100%;
    }
  }
}
.working-tree-top {
  margin-bottom: 50px;
}
.persion-controls {
  transition: 1s;
  &.left-added {
    transform: rotate(-3deg) !important;
    transition: 1s;
    .perion-group {
      transition: 1s;
      width: unset;
      &.one {
        transform: translate(-27px, 0px);
        width: 240px;
        transition: 1s;
      }
    }
  }
}
.person-inner-wrap.right-added {
  transform: rotate(3deg);
  transition: 1s;
  .perion-group {
    transition: 1s;
    width: unset;
    &.saven {
      transform: translate(27px, 0px);
      // width: 240px;
      transition: 1s;
    }
  }
}
.about-content-inner-wrap {
  width: 70%;
  margin: auto;
}
.hero-container-main-wrap {
  position: relative;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: #000000b2;
    position: absolute;
    left: 0;
    top: 0;
  }
  .hero-inner-area-wrap {
    position: relative;
    z-index: 99;
    text-align: center;
    h6 {
      color: orange;
      font-size: 22px;
    }
    h1 {
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 48px;
      line-height: 60px;
      span {
        font-size: 30px;
      }
    }
  }
}
.hero-container-main-wrap.hero-sep-main.tas-hero::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgb(27 63 115);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.7;
}
.your-need-left-khhvbeyvbhdjn h6 {
  color: green;
  margin-top: 70px;
  line-height: 30px;
}
@media (max-width: 640px) {
  .custom_margin {
    margin-top: 25px;
    padding-left: 0px !important;
  }
  .call-to-action-left h6 {
    padding-left: 10% !important;
    margin-top: 30px;
  }

  .react-responsive-modal-modal.customModalSat {
    max-width: unset;
    width: 342px !important;
  }
  // .react-responsive-modal-root {
  //   left: -46px !important;
  // }

  footer.footer-main-area.e-square-footer.tas-footer {
    .footer-bottom-inner {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .call-to-action-inner-wrap.tas-main {
    padding: 60px 40px;
    .call-to-action-left h2 {
      font-size: 40px !important;
      line-height: 52px !important;
    }
  }
  .sales-help-area-wrap {
    padding-bottom: 0px !important;
  }
  .your-need-left-content-wrap h2 {
    font-size: 44px;
  }
  .training-camp-item {
    border-bottom: 1px solid #fff0 !important;
  }

  .youNeedForSales-main-area-wrap.about-2Page.tas-page {
    .your-need-right-chart-content-wrap {
      width: 100%;
      margin: auto;
      text-align: center;
      .your-need-right-chart-content-single {
        color: green;
        font-weight: 600;
        font-size: 10px;
        margin-bottom: -30px;
      }
    }
  }
  .your-need-right-chart-wrap svg {
    width: 100%;
    height: 350px;
  }
  .section-title-area.text-start > h2 {
    font-size: 30px;
    padding: 25px 0;
  }
  .sales-about-2-inner-item-left h2 {
    font-size: 30px;

    line-height: 44px;

    padding: 20px 0px;
  }
  .hero-sep-area-bottom-wrap .training-camp-item {
    border-color: transparent transparent transparent;
    position: relative;
    &::after {
      content: "";
      width: 1px;
      height: 100%;
      background: #fff;
      position: absolute;
      top: 0;
      right: -12%;
    }
  }
  p.mobile-margin-top-gap {
    margin-top: 10px;
  }
  .training-camp-item.know-the-gap {
    border-right: 1px solid transparent !important;
    &::after {
      content: "";
      width: 1px;
      height: 100%;
      background: transparent !important;
      position: absolute;
      top: 0;
      right: -12%;
    }
  }
}
p.mobile-margin-top-gap {
  color: #fff;
  font-family: sora;
  font-size: 12px;
}
