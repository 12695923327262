/* .contact-form-inner-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    align-items: center;
    justify-content: space-between;
} */

.contact-form-left span {
  display: block;
  width: 20px;
  height: 20px;
  background: #1b3f73;
  border-radius: 4px;
  margin-bottom: 15px;
}

.contact-form-left h6 {
  word-spacing: 3px;
  margin-bottom: 15px;
  /* font-family: "Poppins"; */
  text-transform: uppercase;
  font-size: 14px;
}

.contact-form-left h2 {
  font-size: 40px;
  line-height: 58px;
  margin-bottom: 15px;
}

.contact-form-left p {
  margin-bottom: 25px;
}

.from-group {
  margin-bottom: 20px;
}
.from-group label {
  display: block;
  margin-bottom: 10px;
  color: #232536;
}

.from-group input {
  width: 65%;
  line-height: 50px;
  height: 40px;
  font-size: 13px;
  font-family: sora;
  padding-left: 20px;
  background: #f3f8ff;
  border: none;
  border-radius: 5px;
}

.from-group input::placeholder {
  color: #1b407386;
}

.from-group textarea {
  width: 65%;
  height: 120px;
  padding-left: 10px;
  border: none;
  background: #f3f8ff;
  border: none;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

textarea::-webkit-input-placeholder {
  color: #1b407386;
  /* font-family: "poppins"; */
}

button.form-submit-btn {
  padding: 17px 30px;
  border: none;
  background: #1b3f73;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 15px;
}

.contact-form-right {
  height: 100%;
  background: #1b3f73;
  position: relative;
}

span.border-top-right-area {
  position: absolute;
  width: 150px;
  height: 20px;
  right: 0;
  top: -20px;
}

span.border-top-right-area:after {
  content: "";
  width: 75%;
  height: 100%;
  background: #ff0000;

  position: absolute;
  top: 0;
  right: 0;
}

/* span.border-top-right-area::before {
  content: "";
  width: 25%;
  height: 100%;
  background: #1b3f73;
  position: absolute;
  top: 0;
  left: 0;
} */

.contact-form-right-inner {
  padding: 10%;
}

.contact-form-right-from-group h6 {
  color: #ffffffc9;
  font-size: 16px;
  margin-bottom: 30px;
  position: relative;
  margin-top: 50px;
}

.contact-form-right-from-group h6:after {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  box-shadow: 0px -3px 2px 0px #0000004d;
}

.contact-form-right-from-group h3 {
  color: #fff;
  font-size: 20px;
  line-height: 34px;
}

.contact-form-right-from-group p {
  color: #ffffffc7;
  margin-top: 10px;
}

span.border-left-bottom-area {
  position: absolute;
  width: 20px;
  height: 300px;
  display: block;
  /* background: #000; */
  left: -20px;
  bottom: 0;
}

span.border-left-bottom-area::after {
  content: "";
  width: 100%;
  height: 75%;
  background: red;
  position: absolute;
  bottom: 0;
  left: 0;
}

/* span.border-left-bottom-area::before {
  content: "";
  width: 100%;
  height: 25%;
  background: #1b3f73;
  position: absolute;
  bottom: 0;
  left: 0;
} */

.conatct-form-wrap {
  padding: 50px 0;
  padding-top: 150px;
}

/* .office-location-wrap {
  padding: 70px 0;
} */
.office-location-wrap {
  margin: 30px 0;
  border-radius: 10px;
  overflow: hidden;
  min-height: 500px;
}
.office-location-wrap iframe {
  width: 100%;
  min-height: 500px;
}
.office-location-wrap img {
  width: 100%;
}

.social-link a {
  color: #fff;
  display: inline-block;
  margin-right: 25px;
  font-size: 24px;
}

.social-link {
  margin-top: 30px;
}

@media only screen and (max-width: 992px) {
  .contact-form-inner-wrap > .row {
    flex-direction: column-reverse;
  }

  .contact-form-left {
    width: 100%;
  }

  .contact-form-right {
    width: 97%;
    margin-bottom: 30px;
    margin-left: 2.5%;
  }
}
@media only screen and (max-width: 767px) {
  .office-location-wrap {
    padding: 0px 0;
  }
}
@media only screen and (max-width: 576px) {
  .contact-form-right {
    width: 97%;
    margin-bottom: 30px;
    margin-left: 4%;
  }
  .contact-form-inner-wrap .from-group input {
    width: 100%;
  }
  .contact-form-inner-wrap .from-group textarea {
    width: 100%;
  }
  .contact-form-left h2 {
    padding-right: 10% !important;
    font-size: 30px;
    line-height: 42px;
  }
  .from-group label {
    font-size: 13px;
  }
  .office-location-wrap {
    min-height: 350px;
  }
  .office-location-wrap iframe {
    min-height: 350px;
  }
}

.contact-form-right > img {
  width: 270px;
  height: 270px;
  position: absolute;
  z-index: 999;
  right: -71px;
  bottom: 0%;
}
.from-group textarea {
  width: 65%;
  height: 120px;
  padding-left: 20px;
  font-size: 12px;
  padding-top: 16px;
  background: #f3f8ff;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
input::placeholder {
  font-size: 12px !important;
  position: relative;
  top: -1px;
}
.contact-form-left h2 {
  padding-right: 25%;
}

.contact-form-left p {
  padding-right: 25%;
}

.send-message-popup.p-5 {
  text-align: center;
  border-radius: 10px;
}

.react-responsive-modal-modal {
  border-radius: 10px;
}

.send-message-popup img {
  margin-bottom: 25px;
}
