table {
  vertical-align: middle;
  border-color: #dee2e6;
  &.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: #07003e;
    color: #ffffff;
    padding: 10px;
  }
  tr {
    // border: 1px solid #ccc;
    th {
      //   border: 1px solid #ccc;
      padding: 2px 10px;
    }
    td {
      border: 1px solid #ccc;
      padding: 2px 10px;
    }
  }
}
