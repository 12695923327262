.modal-main-area-wrap {
  .modal-dialog {
    .modal-content {
      padding: 15px 30px 40px 30px;
      * {
        font-family: "Sora";
      }
      &:after {
        width: 40%;
        content: "";
        height: 15px;
        position: absolute;
        top: 0;
        left: 0;
        background: #eb2027;
      }
      &:before {
        width: 40%;
        content: "";
        height: 15px;
        position: absolute;
        bottom: 0;
        right: 0;
        background: #eb2027;
      }
      .modal-header {
        border-bottom: none;
        padding-bottom: 0;
        button.btn-close {
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
      .modal-body {
        .expertise-popup-modal-content-wrap {
          h4 {
            font-size: 30px;
            font-weight: 600;
            line-height: 42px;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            margin-bottom: 20px;
          }
          form.contact-form-data-get {
            label {
              font-size: 14px;
            }
            ::placeholder {
              font-size: 14px;
              color: #bdbdbd;
            }
            .form-control {
              height: 40px;
              background: #f3f8ff;
              &:focus {
                box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0) !important;
              }
            }
            textarea.form-control {
              min-height: 100px;
            }
            button {
              width: 100%;
              background: #1b3f73;
              text-align: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
.xampro-submit-successfull-message {
  text-align: center;
  > img {
    height: 100px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  h2 {
    font-size: 16px;
    margin-bottom: 12px;
  }
  h4 {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
