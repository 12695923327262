.dashboard-body-top-content-header {
  margin-bottom: 25px;
  border-radius: 10px;
  //box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
  overflow: hidden;
  .card {
    border: none;
    // align-items: center;
    width: 100%;
    height: 100%;
    .card-body {
      * {
        align-items: center;
        button {
          border: none;
          background: #4eb94d;
          color: #fff;
          padding: 8px 20px;
          border-radius: 10px;
        }
      }
    }
  }
}
.dashboard-data-chart-area-wrap {
  .single-chart-data {
    padding: 20px 22px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
  }
}
.dashboard-right-content {
  .dashboard-right-single-content {
    text-align: center;
    background: #fff;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 20px;
    border-radius: 10px;
    // box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
    margin-bottom: 25px;
    .dashboard-right-single-content-inner-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      span {
        color: #74788d;
      }
      h4 {
        margin-bottom: 0;
      }
    }
  }
}

.single-chart-data {
  margin-bottom: 25px;
  border-radius: 10px;
  overflow-x: scroll;
  //box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
  .card {
    border: none;
  }
  .chart-title-top-content {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    margin-bottom: 15px;
    h4 {
      font-size: 22px;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.apexcharts-datalabels-group > text:first-child {
  display: none;
}
.apexcharts-datalabels-group > text:last-child {
  display: block;
  font-size: 34px;
  position: absolute;
  font-weight: 700;
  transform: translate(0px, 0px);
}
.connector-deployment-status {
  display: flex;
  justify-content: space-between;
  & > * {
    width: 33%;
    padding: 0px 20px;
    p {
      margin-bottom: 5px;
    }
  }
  & > *:nth-child(2) {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}

.connector-deployment-status-bottom {
  display: flex;
  margin: 10px 0;
  padding: 10px 20px;
  border-bottom: 2px solid #dddddd5e;
  > div:first-child {
    width: 170px;
  }
}
form.form-horizontal-form-wrap {
  display: flex;
  gap: 15px;
  align-items: center;
  label.form-label {
    width: 15%;
    display: block;
    font-weight: 600;
    margin-bottom: 0;
  }
  select.form-select {
    font-size: 14px;
    width: 85%;
  }
  .form-data-filtering {
    display: flex;
    gap: 10px;
    align-items: center;

    label.form-label {
      width: 120px;

      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

.dashboard-right-content {
  .dashboard-right-single-content {
    text-align: center;
    background: #fff;
    min-height: 200px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    //box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
    margin-bottom: 25px;
    align-items: center;
    &.hjknm {
      background: #fde8e8;
    }
    &.hvjdbn {
      background: #e7fef7;
    }
    &.hjkdgfnm {
      background: #ffe6d1;
    }
    img {
      width: 46px;
      margin-bottom: 5px;
    }
  }
}

.table > :not(:first-child) {
  border-top: none !important;
}
.table > :not(caption) > * > * {
  // padding: 0.75rem 0.75rem;
  border: none !important;

  border-bottom: 1px solid #f1f1f1 !important;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.dashboard-table {
  tr td {
    border: none;
    td {
      padding: 0;
    }
    &:last-child {
      width: 230px;
      button {
        margin-right: 5px;
      }
    }
    button {
      font-size: 11px;
      padding: 4px 5px;
    }
  }
  & > :not(caption) > * > * {
    border: none !important;
    border-bottom: 1px solid #f1f1f1 !important;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    font-size: 12px !important;
  }
}
.modal-body tr td {
  border: none !important;
  input {
    width: 100% !important;
    :focus {
      background: #f3f8ff;
    }
  }
}
.modal-body {
  .email-template-main-wrap {
    input.form-control {
      width: 100% !important;
      &:focus {
        background: #f3f8ff !important;
      }
    }
  }
}

.email-template-main-wrap {
  .ql-container.ql-snow {
    min-height: 300px;
  }
}
button.form-submit-btn {
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}
