.raffle-draw-page {
  position: fixed;
  width: 100%;
  * {
    font-family: "sora", sans-serif;
  }
  .raffle-draw-main-wrap {
    height: 100vh;
    width: 100%;

    overflow-y: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    .winner-list-position-area-list {
      position: absolute;
      width: 100%;
    }
    // .raffle-draw-right-part-area canvas {
    //   left: 0 !important;
    // }
    .raffle-draw-right-part-area {
      text-align: center;
      margin-top: 27vh;
      p {
        color: #000 !important;
        font-size: 40px;
      }
      button {
        color: #fff;
        background: #e2136e;
        font-size: 20px;
        padding: 10px 30px;
        border-radius: 10px;
        display: inline-block;
        margin-bottom: 10px;
        font-weight: 700;
      }
      .choose-winner-single-info-wrap {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
        background: #0000009c;
        .choose-winner-single-box-wrap {
          width: 600px;
          min-height: 300px;
          background: #fff;
          border: 4px solid #e2136e;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          border-radius: 8px;
          .show-choose-raffle-content {
            position: relative;
            z-index: 999999;
          }
          img.bkash-card-bg-image {
            position: absolute;
            right: 0;
            top: 10px;
            height: 40px;
          }
          .close-button {
            position: absolute;
            top: -20px;
            right: -20px;
            width: 40px;
            height: 40px;
            display: block;
            padding: 0;
            border: 3px solid #fff;
            background: #e2136e;
            i {
              font-size: 30px;
              display: block;
            }
          }
          p {
            color: red;
          }
        }
      }
    }
  }
  //===========================
  .raffle-winner-list-bottom {
    position: relative;
    .reffle-reset-btn {
      text-align: center;
      margin: 40px 0;
      button {
        color: #fff;
        background: #e2136e;
        font-size: 16px;
        padding: 10px 30px;
        border-radius: 10px;
        display: inline-block;
        margin-bottom: 10px;
        border: none;
      }
    }
    // .winner-list-title-bkash {
    //   padding: 50px 0;
    //   text-align: center;
    //   h2 {
    //     color: #fff;
    //     font-size: 50px;
    //     font-weight: 700;
    //     font-family: "Sora", sans-serif;
    //   }
    // }
    margin-top: 35vh;
    .slick-list {
      margin: 0 -10px;
    }
    .slick-slide > div {
      margin: 0 10px;
    }
    .raffle-draw-winner-list-area-wrap {
      .single-raffle-winner {
        // min-height: 200px;
        background: #fff;
        border-radius: 8px;
        padding: 20px 15px;
        text-align: left;
        position: relative;
        box-shadow: 0px 0px 10px 0px #e2136e;
        padding-top: 50px;
        height: 100%;
        .winner-position {
          position: absolute;
          right: -16px;
          width: 40px;
          height: 40px;
          top: -16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #fff;
          box-shadow: 0px 0px 10px 0px #e2136e;
          p {
            margin-bottom: 0;
            font-size: 18px !important;
            font-weight: 700;
          }
        }
        .winner-company-brand-logo {
          position: absolute;
          right: 0;
          top: 10px;
          img {
            width: 120px;
            // height: 50px;
          }
        }
        .bkash-winner-serial-no-bg {
          position: absolute;
          right: 0;
          bottom: 0;
          h1 {
            font-size: 12em;
            opacity: 0.1;
            color: #e2136e;
            line-height: 0.9;
            font-weight: 900 !important;
          }
        }
        p {
          margin-bottom: 7px;
        }
      }
    }
  }
}
