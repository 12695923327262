.phone-number-input {
  position: absolute;
  left: 8px;
  top: 40px;
  font-size: 14px;
  font-weight: 500;
  &:after {
    content: "880";
    position: absolute;
    left: 20px;
    top: 2px;
    font-family: "Sora" !important;
    font-size: 12px;
  }
}
.phone-number-input-wrap {
  padding-left: 44px !important;
  font-family: "Sora" !important;
}
input.phone-number-input-wrap {
  font-size: 12px;
  padding-bottom: 5px;
}
button.form-submit-btn.demo-request-submit-btn {
  padding: 10px 24px;
  width: 140px;
  .spinner-border.text-success {
    width: 20px;
    height: 20px;
  }
}
.demo-request-main-area-wrap {
  .form-control:focus {
    color: #495057;
    background-color: #e8f0fe;
    border-color: #b9bfc4;
    outline: 0;
    box-shadow: none;
  }
  select.xelerator-select-options {
    width: 65%;
    padding: 10px 10px;
    background: #f3f8ff;
    border: none;
  }
}
