.mobile-container {
  max-width: 450px;
  width: 100vw;
  margin: 0 auto;
  background-color: #f2f3fb;
  min-height: 100vh;
  // font-family: "General Sans", sans-serif;
  // min-height: 100vh;
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  button,
  span,
  a {
    font-family: "GeneralSans-Regular", sans-serif !important;
  }
}

.mobile-wrapper {
  padding: 0 15px;
}

.meet-header-banner {
  height: 340px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-area {
  padding-bottom: 40px;
  height: 100%;

  .header-content {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      height: 60px;
    }

    h3 {
      color: #fff;
      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: "dlig" on;
      // font-family: "General Sans";
      font-size: 35px;
      font-style: normal;
      font-weight: 300;
      line-height: 48px; /* 137.143% */
      letter-spacing: 0.35px;
      margin-top: 20px;
      margin-bottom: 5px;
    }

    h2 {
      color: #fff;
      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: "dlig" on;
      // font-family: "General Sans";
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px; /* 109.091% */
      letter-spacing: 0.44px;
    }
  }
}

.grid-area {
  .event-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-top: -50px !important;
    position: relative;
    z-index: 200;
  }
}

.item-card {
  background-color: #fff;
  padding: 25px 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 4px 11px -2px rgba(16, 24, 40, 0.06);
  cursor: pointer;

  h5 {
    margin-top: 13px;
  }
}

.content-area {
  button {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #e2136e;
    width: 100%;
    color: #fff;
    // font-family: "General Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: none;
    margin-top: 30px;
  }
}

.mobile-footer {
  margin-top: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;

  img {
    height: 15px;
  }

  span {
    color: #4c4c4c;
    // font-family: "General Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.back-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  padding: 10px 0;

  .title-area {
    display: flex;
    align-items: center;
    gap: 10px;

    h5 {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: -1px;
    }
  }
}

.event-header {
  min-height: 369px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.event-header {
  h2 {
    color: #fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.32px;
    padding-top: 46px;
  }
}

.image-header {
  min-height: 240px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-modal {
  .alert-icon {
    img {
      height: 50px;
    }
  }

  h2 {
    color: #1c1c1c;
    // font-family: "General Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.24px;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  p {
    color: #1c1c1c;
    // font-family: "General Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
  }

  .contact-number {
    display: flex;
    gap: 10px;

    h5 {
      color: #e2136e;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
}

/* Vertical scrollbar */
.scrollable::-webkit-scrollbar,
.contact-modal-pdf::-webkit-scrollbar {
  width: 0px;
}

/* Horizontal scrollbar */
.scrollable::-webkit-scrollbar-track,
.contact-modal-pdf::-webkit-scrollbar {
  width: 0px;
}

/* Scrollbar thumb */
.scrollable::-webkit-scrollbar-thumb,
.contact-modal-pdf::-webkit-scrollbar {
  background: #888;
  border-radius: 0px;
}

/* Scrollbar buttons */
.scrollable::-webkit-scrollbar-button,
.contact-modal-pdf::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Set properties for other browsers */
.scrollable::-moz-scrollbar,
.contact-modal-pdf::-webkit-scrollbar {
  width: 0px;
}

.contact-modal-pdf {
  max-height: 600px;
  overflow-y: auto;
}
