@import "./variable.scss";

button.status {
  padding: 5px 14px;
  border-radius: 5px;

  &.active_btn {
    color: $centro_success;
    background-color: $centro_success_lite;
    border: 1px solid $centro_success_lite;
    cursor: auto;
  }

  &.complete_btn {
    color: white;
    background-color: $centro_success;
    border: 1px solid $centro_success;
    cursor: auto;
  }

  &.pending_btn {
    color: $centro_danger;
    background-color: $centro_danger_lite;
    border: 1px solid $centro_danger_lite;
    cursor: auto;
  }
}

.bg-table-primary {
  color: #fff;
  background-color: $centro_primary_color !important;
}

.add_category_section {
  .ql-editor.ql-blank {
    height: 100px;
  }
}
