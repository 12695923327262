.xam-pro {
  header.header-main-area-wrap.sep-new-page.lafarge-page-header {
    background: #ffffff;
    box-shadow: 0px 10px 20px rgb(174 174 174 / 20%);
  }
  .tas.lafarge-main-wrap {
    overflow: hidden;
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
      width: 70px;
      height: 70px;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 4px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 12px;
      height: 12px;
      background: #f87da9;
      position: absolute;
      top: 6px;
      left: 6px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .lafarge-single-ques-inner {
    .ques-options-wrap {
      display: flex;
    }
  }
  .mb-2.position-relative.ques-label-round {
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid rgba(216, 216, 216, 0.3803921569);
    box-shadow: 0px 10px 30px rgb(166 176 207 / 20%);
    margin-bottom: 30px !important;
    padding: 30px 0;
    opacity: 0.4;
    &.active {
      opacity: 1;
    }
  }
  .mb-2.position-relative.ques-label-round > * > label:nth-child(1) {
    margin-bottom: 25px;
    margin-top: 15px;
    font-size: 30px;
    font-weight: 600;
    font-family: "Sora";
    &::after {
      content: "Disagree";
      position: absolute;
      bottom: 65px;
      right: 10%;
      font-weight: 700;
      // color: #98708c;
      color: red;
      font-size: 18px;
    }
    &::before {
      content: "Agree";
      position: absolute;
      bottom: 65px;
      left: 10%;
      font-weight: 700;
      color: green;
      font-size: 18px;
    }
  }
  .mb-2.position-relative.ques-label-round {
    position: relative;
  }
  .mb-2.position-relative.ques-label-round > * > label:nth-child(3) {
    &::after {
      width: 48px;
      height: 48px;
      background: green;
    }
    &::before {
      width: 60px;
      height: 60px;
      border-color: green !important;
      border-width: 4px !important;
    }
  }
  .mb-2.position-relative.ques-label-round > * > label:nth-child(5) {
    position: relative;
    top: 7px;
    left: 15px;
    &::after {
      width: 33px;
      height: 33px;
      background: green;
    }
    &::before {
      width: 45px;
      height: 45px;
      border-color: green !important;
      border-width: 4px !important;
    }
  }
  .mb-2.position-relative.ques-label-round > * > label:nth-child(7) {
    position: relative;
    top: 15px;
    left: 20px;
    &::after {
      width: 30px;
      height: 30px;
      background: #a9a9a9;
      top: 0;
      left: 0;
    }
    &::before {
      width: 30px;
      height: 30px;
    }
  }
  .mb-2.position-relative.ques-label-round > * > label:nth-child(9) {
    position: relative;
    top: 6px;
    left: 5px;
    &::after {
      width: 33px;
      height: 33px;
      background: red;
    }
    &::before {
      width: 45px;
      height: 45px;
      border-color: red !important;
      border-width: 4px !important;
    }
  }
  .mb-2.position-relative.ques-label-round > * > label:nth-child(11) {
    &::after {
      width: 48px;
      height: 48px;
      background: red;
    }
    &::before {
      width: 60px;
      height: 60px;
      border-color: red !important;
      border-width: 4px !important;
    }
  }

  .lafarge-form-main-wrap {
    padding: 100px 0;
    min-height: 87vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    > * {
      width: 100%;
    }
    label.form-label {
      font-weight: 600;
    }
  }
  .lafarge-header-main-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .lafarge-inner-form-wrap {
    background: #ffffff;
    box-shadow: 0px 10px 30px rgb(166 176 207 / 20%) !important;
    border-radius: 30px;
    min-height: 450px;
    padding: 50px 0;
    .form-control {
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
      color: #666;
      height: 50px;
      &:focus {
        outline: none;
      }
    }
    ::placeholder {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .btn.lafarge-first-stap-button {
    background: #1b3f73;
    color: #fff;
    padding: 10px 30px;
    height: 50px;
    font-weight: 600;
  }

  .lafarge-step-three-form-main-wrap {
    padding: 50px 0;
    label.form-label {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
  .lafarge-success-inner-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    img {
      margin-bottom: 10px;
    }
    h3 {
      margin-bottom: 0px;
    }
    a {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    .mb-2.position-relative.ques-label-round > * > label:nth-child(3) {
      left: 55px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(5) {
      left: 35px;
      top: 4px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(7) {
      right: -12px;
      top: 7px;
      left: unset;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(9) {
      right: 15px;
      top: 5px;
      left: unset;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(11) {
      right: 40px;
      top: 3px;
      left: unset;
    }

    .mb-2.position-relative.ques-label-round > * > label:nth-child(3)::before {
      width: 40px;
      height: 40px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(3)::after {
      width: 28px;
      height: 28px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(5)::before {
      width: 36px;
      height: 36px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(5)::after {
      width: 23px;
      height: 23px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(11)::before {
      width: 40px;
      height: 40px;

      border-width: 4px !important;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(11)::after {
      width: 27px;
      height: 27px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(9)::before {
      width: 36px;
      height: 36px;

      border-width: 4px !important;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(9)::after {
      width: 23px;
      height: 23px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::before {
      bottom: 75px;
      left: 20%;
      font-weight: 700;
      color: green;
      font-size: 14px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::after {
      bottom: 75px;

      right: 20%;

      font-size: 14px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1) {
      margin-bottom: 15px;
      margin-top: 30px;
      font-size: 18px;
      font-weight: 700;
    }
    .hero-container-main-wrap .hero-inner-area-wrap h1 {
      font-size: 22px;
      line-height: 35px;
    }
  }

  @media screen and (max-width: 576px) {
    .mb-2.position-relative.ques-label-round > * > label:nth-child(9)::after {
      width: 22px;
      height: 22px;
      left: 7px;
      top: 7px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::before {
      bottom: 75px;
      left: 12%;
      font-weight: 700;
      color: green;
      font-size: 14px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::after {
      bottom: 75px;
      right: 12%;
      font-size: 14px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1) {
      margin-bottom: 15px;
      margin-top: 30px;
      font-size: 18px;
      font-weight: 700;
    }
    .hero-container-main-wrap .hero-inner-area-wrap h1 {
      font-size: 22px;
      line-height: 35px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(3)::after {
      width: 26px;
      height: 26px;
      top: 7px;
      left: 7px;
    }
  }
  @media screen and (max-width: 470px) {
    .mb-2.position-relative.ques-label-round > * > label:nth-child(9)::after {
      width: 22px;
      height: 22px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::before {
      bottom: 75px;
      left: 7%;
      font-weight: 700;
      color: green;
      font-size: 14px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::after {
      bottom: 75px;
      right: 7%;
      font-size: 14px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1) {
      margin-bottom: 15px;
      margin-top: 30px;
      font-size: 18px;
      font-weight: 700;
    }
    .hero-container-main-wrap .hero-inner-area-wrap h1 {
      font-size: 22px;
      line-height: 35px;
    }
  }
  @media screen and (max-width: 400px) {
    .mb-2.position-relative.ques-label-round > * > label:nth-child(9)::after {
      width: 22px;
      height: 22px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::before {
      bottom: 75px;
      left: 2%;
      font-weight: 700;
      color: green;
      font-size: 14px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::after {
      bottom: 75px;
      right: 2.3%;
      font-size: 14px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1) {
      margin-bottom: 15px;
      margin-top: 30px;
      font-size: 18px;
      font-weight: 700;
    }
    .hero-container-main-wrap .hero-inner-area-wrap h1 {
      font-size: 22px;
      line-height: 35px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(3) {
      left: 64px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(5) {
      left: 40px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(9) {
      right: 22px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(11) {
      right: 50px;
    }
  }
  @media screen and (min-width: 1100px) {
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::before {
      bottom: 65px;
      left: 15%;
      font-weight: 700;
      color: green;
      font-size: 18px;
    }
    .mb-2.position-relative.ques-label-round > * > label:nth-child(1)::after {
      content: "Disagree";
      position: absolute;
      bottom: 65px;
      right: 10%;
      font-weight: 700;
      color: red;
      font-size: 18px;
    }
  }

  .lafarge-inner-form-wrap {
    span.start-phone-number {
      position: absolute;
      z-index: 9;
      top: 42px;
      left: 22px;
      font-family: "sora";
      font-size: 13px;
      font-weight: 600;
      color: #666;
    }
    input.start-phone-number {
      padding-left: 50px !important;
      font-family: "sora";
      font-size: 13px;
    }
  }
}
