.zoom-in-zoom-out {
  margin: 24px;
  width: 50px;
  height: 50px;

  animation: zoom-in-zoom-out 3s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.7, 0.7);
    filter: grayscale(1);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.7, 1.7);
    opacity: 1;
    filter: grayscale(0);
  }
  100% {
    transform: scale(0.7, 0.7);
    opacity: 0.2;
    filter: grayscale(1);
  }
}
