.footer-top {
  padding: 50px 0;
  background: #f9fbff;
}

footer.footer-main-area {
  position: relative;
  z-index: 999;
  padding-top: 40px;
}

ul.footer-nav li a:hover {
  color: red;
}

.footer-top-item-right {
  display: flex;
  grid-gap: 5vw;
  gap: 5vw;
  width: 55%;
  justify-content: space-between;
}

ul.footer-nav li a {
  /* font-weight: 600; */
  display: inline-block;
  padding-bottom: 12px;
  font-size: 16px;
  text-transform: capitalize;
}

.footer-top-right-single-item ul {
  padding-left: 0;
  position: relative;
}

.footer-top-right-single-item ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}

.footer-top-right-single-item ul li a {
  text-decoration: none;
  color: #000;
  padding-bottom: 8px;
  display: inline-block;
  font-size: 16px;
}

.footer-top-right-title h6 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
}

.footer-bottom-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom-left p {
  color: #fff;
}

.footer-bottom-right a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin-left: 15px;
}
.footer-bottom {
  background: #eff3fc;
  padding: 25px 0;
}

.footer-top-item-left img {
  display: inline-block;
  margin-bottom: 15px;
}

.footer-top-item-left h6 {
  font-size: 24px;
  margin-bottom: 10px;
}

.service-items ul li a {
  font-size: 14px;
  font-weight: 500;
}

.feature-single-item img {
  display: inline-block;
  height: 40px;
  filter: grayscale(1);
}

/* .footer-top-right-single-item.pl-2 {
  padding-left: 25%;
} */

ul.footer-nav {
  margin-bottom: 0px;
}

ul.footer-nav:after {
  content: "";
  width: 90%;
  height: 1px;
  background: #1b3f7329;
  position: absolute;
  left: 0;
  bottom: 0;
}

.footer-top-right-single-item.pl-3 {
  padding-left: 25%;
}

.footer-top-right-single-item.pl-3 {
  padding-left: 20%;
}

@media only screen and (max-width: 992px) {
  .footer-top-item-left {
    width: 100%;
    /* text-align: center; */
  }

  .footer-top-item-right {
    width: 100%;
    display: none;
  }

  .footer-top-inner {
    display: block;
    gap: 1vw;
    justify-content: space-between;
  }

  /* .service-items img {
    width: 100%;
  } */

  .footer-top-inner > * > * > * {
    margin-bottom: 30px;
  }
  .col-lg-4.footer-item-hide {
    display: none;
  }
  .footer-top-right-single-item.pl-3 {
    padding-left: 0;
  }
}

.service-items img {
  height: 40px;
}

.footer-top-item-left > a > img {
  height: 60px;
  margin-bottom: 10px;
}
.footer-top-item-left.----jhnbkduhvc > a > img {
  height: 45px;
  margin-bottom: 10px;
}

.footer-top-item-left h6 {
  font-size: 15px;
  margin-bottom: 10px;
  color: gray;
}
.footer-top-item-left h6 {
  font-size: 15px;
  margin-bottom: 10px;
  color: gray;
}

.service-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}
.footer-left-text-p p {
  padding-left: 37px !important;
  font-size: 9px;
  font-weight: 500;
}

/* .service-items img:nth-child(2) {
  margin-left: 50px;
} */

@media only screen and (max-width: 768px) {
  .footer-item-hide {
    display: none;
  }

  .footer-top-item-left {
    text-align: center;
  }

  .footer-top-item-left p {
    font-size: 10px;
    width: 70%;
    margin: auto;
  }

  .footer-top-inner > .row {
    justify-content: center;
    text-align: center;
    display: block;
  }

  .footer-top-inner > .row > * {
    width: 100%;
  }

  .footer-top-item-left p {
    font-size: 10px;
    width: 80%;
    margin: auto;
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 992px) {
  .footer-top-item-left > a > img {
    height: 40px;
    margin-bottom: 30px;
  }

  .footer-top-right-single-item ul li {
    margin-right: 10px;
  }

  .footer-top-right-single-item ul li a {
    padding-bottom: 0px;

    font-size: 13px;
  }

  .insights-nav-items {
    text-align: center;
  }
}
.footer-bottom-left {
  display: flex;
  align-items: center;
  gap: 30px;
}
.footer-bottom-left img {
  height: 35px;
}
.footer-bottom-right p {
  margin-bottom: 0;
}
